const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-8x2iszs6rbxn"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://exls2a5om0.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_5rzuirFfp",
    APP_CLIENT_ID: "66t7jvs2klf99au6d3t9rknhje",
    IDENTITY_POOL_ID: "us-east-1:dd7dc0d1-5bd8-4408-8c04-532de1dc3aca"
  },
  STRIPE_KEY: "pk_test_t3mGLWvWZl5dbW8cxa3z3lIp00LKazLA8e"
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-14uj2q4i3764r"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://vonjvwhcvl.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_pHhLEphnn",
    APP_CLIENT_ID: "4mp099pnt7v9lucjie7f3a3l94",
    IDENTITY_POOL_ID: "us-east-1:f484325f-5643-4a16-9227-229febd446a8"
  },
  STRIPE_KEY: "pk_test_t3mGLWvWZl5dbW8cxa3z3lIp00LKazLA8e"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  social: {
    FB: "362089007814435"
  },
  ...config
};
